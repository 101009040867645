import cn from 'classnames';
import React from 'react';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import StateButton from '@/dmPortal/ui/components/Applications/ApplicationCard/StateButton';
import styles from './applicationCard.scss';

interface IProps {
  app: {
    tenant: string,
    state: string,
    name: string,
    description: string,
    iconUrl: string,
    coverImgUrl: string,
    learnMoreUrl: string,
    isStateless: string,
  },
  index: number,
}

function ApplicationCard(props: IProps) {
  const { isStateless, tenant } = props.app;

  const showNewAppLabel = TenantConfig.fromCode(tenant)?.options?.isNewApp ?? false;

  return (
    <div className={ styles.wrp }>
      { showNewAppLabel && <span className={ styles.newCard }>New</span> }
      <div className={ styles.cover }>
        <img className={ styles.coverImage } src={ props.app.coverImgUrl } alt="" />
      </div>
      <div className={ styles.iconWrp }>
        <div>
          <img
            alt={ props.app.tenant }
            src={ props.app.iconUrl }
            className={ cn(styles.icon) }
          />
        </div>
      </div>
      <div className={ styles.description }>
        <h3>{ props.app.name }</h3>
        <p>{ props.app.description }</p>
      </div>
      <div className={ styles.buttons }>
        { !!props.app.learnMoreUrl && (
          <a
            className={ styles.learnMore }
            href={ props.app.learnMoreUrl }
            target="_blank"
            data-test={ `${ props.app.tenant }LearnMoreLink` }
          >
            Learn more
          </a>
        ) }
        { !isStateless && (
          <StateButton
            app={ props.app }
            index={ props.index }
          />
        ) }
      </div>
    </div>
  );
}

export default ApplicationCard;
